import React from "react";
import { Box, Flex } from "rebass";
import useStories from "../../hooks/stories";

const Navigator = () => {
  const { pages, currentStoryIndex } = useStories();

  return (
    <Flex
      flexDirection="row"
      sx={{
        position: ["absolute"],
        backgroundColor: "#111",
        paddingTop: "2px",
        width: ["auto", "650px"],
        top: ["initial"],
        bottom: [0, "10px"],
        left: [0, "50%"],
        right: [0, "initial"],
        marginLeft: ["initial", "-325px"],
        zIndex: 100,
      }}
    >
      {pages.map((page, index) => (
        <Box
          key={index}
          sx={{
            flexGrow: 1,
            height: "3px",
            backgroundColor: `rgba(255, 255, 255, ${
              index <= currentStoryIndex ? 1 : 0.3
            })`,
            borderLeft: index === 0 ? "none" : "3px solid #111",
          }}
        />
      ))}
    </Flex>
  );
};

export default Navigator;
