import React, { useState } from "react";
import _ from "lodash";
import { Box, Flex, Text } from "rebass";
import { Input as InputComponent } from "@rebass/forms";
import { css } from "emotion";
import { calculateRatio, resetVideoPlayback } from "../../../utils";
import { hasError } from "../../../utils/validator";
import useStories from "../../../hooks/stories";
import { shake } from "../../../styles/animations";

const Input = ({ inputType, field, text, placeholder, layout }) => {
  const {
    showInteractionFeedback,
    pages,
    currentStoryIndex,
    videoRefs,
    dispatch,
  } = useStories();

  const [inputValue, setInputValue] = useState("");
  const [validationError, setValidationError] = useState(null);

  const inputTypeMapping = {
    text: "text",
    phone: "number",
    email: "email",
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!inputValue) {
      return;
    }

    resetVideoPlayback({
      videoRefs,
    });

    dispatch({
      type: "IS_VIDEO_PLAYING_TOGGLE",
      payload: false,
    });

    setValidationError(null);

    const error = hasError({
      value: inputValue,
      type: inputType,
      customValidations: {},
    });

    if (error) {
      return setValidationError(error.errorMessage);
    }

    let fields = {};

    fields[field] = inputValue;

    dispatch({
      type: "FIELDS_ADD",
      payload: fields,
    });

    dispatch({
      type: "IS_INPUT_FOCUSED_TOGGLE",
      payload: false,
    });

    const nextStoryId = _.get(pages[currentStoryIndex + 1], "id");

    if (videoRefs[nextStoryId] && videoRefs[nextStoryId].current) {
      videoRefs[nextStoryId].current.play();

      dispatch({
        type: "IS_VIDEO_PLAYING_TOGGLE",
        payload: true,
      });
    }

    dispatch({
      type: "PAGE_NEXT",
    });
  };

  return (
    <Box
      onFocus={() => {
        dispatch({
          type: "IS_INPUT_FOCUSED_TOGGLE",
          payload: true,
        });
      }}
      onBlur={() => {
        dispatch({
          type: "IS_INPUT_FOCUSED_TOGGLE",
          payload: false,
        });
      }}
      className={css`
        position: ${layout.top || layout.left ? "absolute" : "relative"};
        top: ${layout.top ? `${calculateRatio(layout.top)}px` : "initial"};
        left: ${layout.left ? `${calculateRatio(layout.left)}px` : "initial"};
        border-radius: ${calculateRatio(5)}px;
        max-width: 75%;
        background-color: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
        z-index: 10;
        transition: top 0.15s ease;

        ${showInteractionFeedback &&
        `
          animation: ${shake} 0.5s ease;
        `}
      `}
    >
      <form onSubmit={handleSubmit}>
        <Box
          className={css`
            padding: ${calculateRatio(16)}px;
            text-align: center;
          `}
        >
          <Text
            className={css`
              font-size: ${calculateRatio(16)}px;
              margin-bottom: ${calculateRatio(8)}px !important;
            `}
          >
            {text}
          </Text>

          <Flex flexDirection="row" alignItems="stretch">
            {inputType === "phone" && (
              <Flex
                alignItems="center"
                sx={{
                  border: "1px solid #cccccc",
                  backgroundColor: "#f6f6f6",
                  color: "#a4a4a4",
                  py: 2,
                  px: 3,
                  borderTopLeftRadius: 4,
                  borderBottomLeftRadius: 4,
                }}
              >
                <Text>{"+39"}</Text>
              </Flex>
            )}

            <InputComponent
              value={inputValue}
              type={inputTypeMapping[inputType]}
              placeholder={placeholder}
              pattern={inputType === "phone" ? `[0-9]*` : null}
              className={css`
                padding-right: ${calculateRatio(62)}px !important;
                background-color: #eeefef !important;
                border: 0 !important;
                padding: ${calculateRatio(8)}px ${calculateRatio(16)}px !important;
                font-size: ${calculateRatio(16)}px !important;

                ${inputType === "phone"
                  ? `
                    border-top-left-radius: 0 !important;
                    border-bottom-left-radius: 0 !important;
                  `
                  : ``}
              `}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </Flex>

          {!!validationError && (
            <Text mt={1} color="danger" fontSize={0}>
              {validationError}
            </Text>
          )}
        </Box>

        <button
          type="submit"
          className={css`
            width: 100%;
            background: transparent;
            padding: ${calculateRatio(12)}px;
            font-size: ${calculateRatio(16)}px !important;
            border: 0;
            border-top: 1px solid #d8d8d8;
            color: #0fa3f0;
            font-weight: 700;
            outline: none;
          `}
        >
          Invia
        </button>
      </form>
    </Box>
  );
};

export default Input;
